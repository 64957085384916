
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class extends Vue {
  private times = [
    {
      value: '오후 4:00 ~ 오후 4:15',
      label: '오후 4:00 ~ 오후 4:15',
    },
    {
      value: '오후 4:15 ~ 오후 4:30',
      label: '오후 4:15 ~ 오후 4:30',
    },
  ];

  private time = '오후 4:00 ~ 오후 4:15';
}
